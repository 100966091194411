<template>
  <div style="width:100%;height:100%;text-align:center">
   <img src="/static/page-under-construction.jpg" style="width:90%;height:auto;"><img>   
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {

    };
  },
  computed: {},
  watch: {

  },
  methods: {

  },
  created() {
    
  },
  mounted: function () {
    
  },
};
</script>
<style>

</style>
