<template>
  <div style=" font-family: Lato; sans-serif;margin:10px;text-align:left;">

    <div>We would be happy to meet you!</div>
    
      <div>Please let us know if you have any suggestion or question.</div>
<BR></BR>
    <div>Warranty and Returns: <BR></BR><SPAN style="margin-left:10px;">littledot.lab@gmail.com</SPAN></div>
     <BR></BR>
    <div>Business and Sales: <BR></BR> <SPAN style="margin-left:10px;">ian@littledotus.com</SPAN></div>
     <BR></BR>
    <div>
      Buy LittleDot: <BR></BR>
      <SPAN style="margin-left:10px;"><a href="https://littledotus.com/">https://littledotus.com/</a></SPAN>
    </div>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted: function () {},
};
</script>
<style>
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/S6u9w4BMUTPHh7USeww.woff) format("woff");
}
</style>
